import React, { useEffect, useState } from "react";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import HConfig from "../../../helpers/Config";
import { Helmet } from "react-helmet";

// import PaytmConfig from "./PaytmConfig";
const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

const PaymentProcessing = () => {
  const [siteTitle, setSiteTitle] = useState(HConfig["SITE_TTILE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Payment Processing</title>
        <meta
          name="description"
          content={siteTitle + " - Payment Processing"}
        />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>

      <SiteBreadcrumb title="Checkout" />

      <section className="checkout-area ptb-60">
        <div className="container">
          {/* <div className="row">
                              <div className="col-lg-12 col-md-12">
                                  <div className="user-actions">
                                      <i className="fas fa-sign-in-alt"></i>
                                      <span>Returning customer? <Link to="#">Click here to login</Link></span>
                                  </div>
                              </div>
                          </div> */}

          <div>
            {/* Show a loading message or any other UI while processing the payment */}
            <p>Processing payment...</p>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default PaymentProcessing;
