import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate, Navigate } from "react-router-dom";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  setLanguageCodeInSession,
} from "../../../helpers/CommonHelper";
import { makeProductShortDescription } from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";

import {
  checkIfStringIsEmtpy,
  showInfoMsg,
} from "../../../helpers/ValidationHelper";

// login

import LoginUserModal from "../modal/LoginUserModal";

const TopHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);
  const [langCode, setLangCode] = useState("");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const handleWishlist = () => {
    setDisplay(!display);
  };

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const HandleLogout = (e) => {
    localStorage.setItem("user", JSON.stringify("{}"));
    dispatch(rootAction.userAction.setUser("{}"));

    navigate("/", { replace: true });
  };

  const totalCartItems = useSelector(
    (state) => state.cartReducer.totalCartItems
  );

  const handleCart = (event) => {
    event.preventDefault();

    if (
      totalCartItems != null &&
      totalCartItems != null &&
      totalCartItems > 0
    ) {
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    } else {
      showInfoMsg("No item exists in your cart");
    }
  };

  const handleLangCodeInSession = async (value) => {
    await setLanguageCodeInSession(value);
    await setLangCode(value);
    let homeUrl = "/" + value + "/";
    window.location.href = homeUrl;
    // navigate(homeUrl, { replace: true });
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["TopHeader"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <ul className="top-header-nav">
                <li>
                  <Link
                    to={`/${getLanguageCodeFromSession()}/about`}
                    id="lbl_thead_about"
                  >
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "About",
                          "lbl_thead_about"
                        )
                      : "About"}
                  </Link>
                </li>
                {/* <li><Link to="/"><a>Our Stores</a></Link></li> */}
                <li>
                  <Link
                    to={`/${getLanguageCodeFromSession()}/faq`}
                    id="lbl_thead_faq"
                  >
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          " FAQ's",
                          "lbl_thead_faq"
                        )
                      : "FAQ's"}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${getLanguageCodeFromSession()}/contact-us`}
                    id="lbl_thead_contct"
                  >
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Contact",
                          "lbl_thead_contct"
                        )
                      : "Contact"}
                  </Link>
                </li>

                <li>
                  <Link
                    to={`/${getLanguageCodeFromSession()}/become-seller`}
                    id="lbl_thead_seller"
                  >
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Become Seller",
                          "lbl_thead_seller"
                        )
                      : "Become Seller"}
                  </Link>
                </li>
                <>
                  {loginUser != null &&
                  loginUser != undefined &&
                  loginUser.UserID != undefined &&
                  loginUser.UserID > 0 ? (
                    <li>
                      <div className="option-item  text-center me-0">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            HandleLogout(e);
                          }}
                          id="lbl_mgmenu_logout"
                          className="d-flex flex-column text-center"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Logout",
                                "lbl_mgmenu_logout"
                              )
                            : ""}
                          <>Logout</>
                        </Link>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
