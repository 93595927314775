import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import HConfig from "../../../helpers/Config";
import { Helmet } from "react-helmet";
import rootAction from "../../../stateManagment/actions/rootAction";

// import PaytmConfig from "./PaytmConfig";
const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const [siteTitle, setSiteTitle] = useState(HConfig["SITE_TTILE"]);
  const [orderNumber, SetOrderNumber] = useState(
    localStorage.getItem("TXN_ORDER") ?? ""
  );
  const [orderAmount, SetOrderAmont] = useState(
    localStorage.getItem("TXN_AMOUNT") ?? 0
  );
  useEffect(() => {
    dispatch(rootAction.cartAction.setCustomerCart("[]"));
    dispatch(rootAction.cartAction.SetTotalCartItems(0));
    localStorage.setItem("cartItems", "[]");
    localStorage.removeItem("TXN_AMOUNT");
    localStorage.removeItem("TXN_ORDER");
    localStorage.removeItem("TXN_TOKEN");
  });
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Payment Success</title>
        <meta name="description" content={siteTitle + " - Payment Success"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>

      <SiteBreadcrumb title="paymentsuccess" />

      <section className="checkout-area ptb-60">
        <div className="container">
          <div>
            <div className="product-details-content">
              <h1>Order Placed Successfully!</h1>
              <br></br>
            </div>
          </div>
          <div>
            <ul className="product-info">
              <li>
                <p>Thank you for your order!</p>
              </li>
              <li>
                <p>Your Order Number: {orderNumber}</p>
              </li>
              <li>
                <p>Total Amount: {orderAmount}</p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default PaymentSuccess;
