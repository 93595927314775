import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";


const Sitemap = () => {
  const [xmlContent, setXmlContent] = useState("");

  useEffect(() => {
    const fetchXml = async () => {
      try {
        const response = await fetch("/sitemap.xml");
        const content = await response.text();
        setXmlContent(content);
      } catch (error) {
        console.error("Error fetching XML:", error);
      }
    };

    fetchXml();
  }, []);

  return (
    <div>
      <h1>XML Viewer</h1>
      <SyntaxHighlighter language="xml" style={solarizedlight}>
        {xmlContent}
      </SyntaxHighlighter>
    </div>
  );
};

export default Sitemap;
