import React, { useEffect, useState } from "react";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Config from "../../../helpers/Config";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const FaqPage = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["FaqPage"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Frequently Asked Questions (FAQ)</title>
        <meta
          name="description"
          content={siteTitle + " - Frequently Asked Questions (FAQ)"}
        />
        <meta name="keywords" content="Frequently Asked Questions, FAQ"></meta>
      </Helmet>

      <SiteBreadcrumb title="FAQ's" />

      <section className="faq-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span className="dot"></span>
              <span id="lbl_faq_pagetitle">
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Frequently Asked Questions",
                      "lbl_faq_pagetitle"
                    )
                  : "Frequently Asked Questions"}
              </span>
            </h2>
          </div>

          <div className="faq-accordion">
            <ul className="accordion">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I place an order?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      To place an order, simply browse our website and add the
                      desired items to your shopping cart. Follow the checkout
                      process, where you'll provide your shipping details and
                      payment information.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What payment methods do you accept?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We accept major credit cards, debit cards, UPI and Paytm
                      for secure and convenient transactions.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I modify or cancel my order after placing it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Once an order is confirmed, modifications or cancellations
                      may not be possible. Please review your order carefully
                      before completing the purchase. For urgent concerns,
                      contact our customer support team.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I track my order?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Once your order is shipped, login and you will find in
                      order status. Use this number to track your shipment on
                      our website or the carrier's site.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is your return policy?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our return policy allows you to return items within 7 days
                      of delivery. Please refer to our Return Policy for
                      detailed instructions on initiating a return.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you offer international shipping?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we offer international shipping. Shipping costs and
                      delivery times vary by destination. Feel free to contact
                      us for more information.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I contact customer support?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our customer support team is available here to assist you.
                      You can also reach out via email at help@bassinterio.com
                      or through our Contact Us page.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Are the colors of the products accurate in the photos?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      While we strive for accuracy in portraying product colors,
                      variations may occur due to screen settings. Please refer
                      to product descriptions for additional details and, if
                      needed, contact us for more information.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I customize or personalize furniture items?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Some of our products offer customization options. Check
                      the product description or contact our customer support
                      team to inquire about personalized options.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What should I do if my item arrives damaged or defective?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      In the rare event of a damaged or defective item, please
                      contact our customer support team immediately. Provide
                      photos and a detailed description, and we will work to
                      resolve the issue promptly.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you offer gift cards?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we offer gift cards in various denominations. Keep an
                      eye on our website and social media channels for any
                      promotions or special discounts on gift cards.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I subscribe to your newsletter for updates and
                      promotions?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Subscribe to our newsletter on the homepage or during the
                      checkout process. Stay informed about new arrivals,
                      promotions, and exclusive offers.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </ul>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default FaqPage;
