import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import about1 from "../../resources/themeContent/images/theme-images/about1.jpg";
import about2 from "../../resources/themeContent/images/theme-images/about2.jpg";
import signature from "../../resources/themeContent/images/theme-images/signature.png";

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>Our Vision ....</h2>
                <p>
                  Our team of skilled craftsmen and workers makes elegant and
                  alluring designs and furnitures with their experiments and
                  ceativity. But still products need to be customizedas per the
                  house/office. Thus, our vision is to make customized
                  furnitureat first priority as per the demand of the customer
                  along with the designs available with us on our platform.
                </p>
                <br></br>
                <br></br>
                <br></br>
                <h2>Quality Products ....</h2>
                <p>
                  There is always a doubt regarding the quality in the mind of
                  the customers. Therefore, along with the design of the
                  product, its quality is also taken care of. For that, best
                  quality raw material are product. Every single detail of
                  designing, finishing, polishing are taken into consideration
                  before the approval of final product and its delivery.
                </p>
                <br></br>
                <br></br>
                <br></br>
                <h2>And lastly, Budget ....</h2>
                <p>
                  Simply as requirement our prices are competitive and fair.
                  There are no surprise bills with a sincere commitment. Any
                  unexpected expenses on custom furniture demand must be
                  pre-approved by you.Thats how we would like to be treated and
                  the is how our clients are treated. 
                </p>

                {/* <div className="signature mb-0">
                  <img src={signature} alt="image" />
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                <img src={about2} className="about-img2" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default About;
