import React, { useState } from "react";

function ShareMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ShowShareMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const FacebookShare = () => {
    // Implement Facebook share logic here
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(window.location.href),
      "_blank"
    );
  };

  const TwitterShare = () => {
    // Implement Twitter share logic here
    window.open(
      "https://twitter.com/intent/tweet?url=" +
        encodeURIComponent(window.location.href),
      "_blank"
    );
  };

  const WhatsAppShare = () => {
    // Implement WhatsApp share logic here
    window.open(
      "https://api.whatsapp.com/send?text=" +
        encodeURIComponent(document.title + " " + window.location.href),
      "_blank"
    );
  };

  const OtherShare = () => {
    // Implement Other share logic here
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };

  return (
    <div>
      <label id="shareButton" onClick={ShowShareMenu} className="ti-sharethis">
        {" "}
        Share This:
      </label>
      {isMenuOpen && (
        <div id="floating-menu" className="card floatingmenu">
          <ul className="list-group list-group-horizontal listmenu">
            <li className="list-group-item" onClick={FacebookShare}>
              <label>
                <i className="fa fa-facebook fbook"></i> Facebook
              </label>
            </li>
            <li className="list-group-item" onClick={TwitterShare}>
              <label>
                <i className="fa fa-twitter Twitter"></i> Twitter
              </label>
            </li>
            <li className="list-group-item" onClick={WhatsAppShare}>
              <label>
                <i className="fa fa-whatsapp whatsapp"></i> WhatsApp
              </label>
            </li>
            {/*<li className="list-group-item" onClick={InstagramShare}>
                            <label><i className="fa fa-instagram instagram"></i> Instagram</label>
                        </li>*/}
            {/* <li className="list-group-item" onClick={PinterestShare}>
                            <label><i className="fa-pinterest"></i> Pinterest</label>
                        </li>*/}
            <li className="list-group-item" onClick={OtherShare}>
              <label>
                <i className="fa fa-share"></i> Others
              </label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ShareMenu;
