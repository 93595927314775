import React, { useEffect, useState } from "react";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import HConfig from "../../../helpers/Config";
import { Helmet } from "react-helmet";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
  GetLoadSTRPPublishable,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { injectCheckout } from "paytm-blink-checkout-react";
// import PaytmConfig from "./PaytmConfig";
const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

const appendHandler = (config) => {
  const newConfig = { ...config };
  newConfig.handler = {
    notifyMerchant: notifyMerchantHandler,
  };
  return newConfig;
};
const notifyMerchantHandler = (eventType, data) => {
  if (eventType === "APP_CLOSED") {
    PaymentFailedAPI();
  }
  console.log("MERCHANT NOTIFY LOG", eventType, data);
};
const PaymentFailedAPI = async () => {
  const PaytmFailedConfig = {
    orderId: localStorage.getItem("TXN_ORDER"),
  };
  const headersStrip = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const cResponse = await MakeApiCallAsync(
    Config.END_POINT_NAMES["MakeFailedPayment"],
    Config["BASE_CONTROLLER_SUB_URL"],
    PaytmFailedConfig,
    headersStrip,
    "POST",
    true
  );
  window.location.href = "/paymentfailed";
};
const PaymentProcess = () => {
  const PaytmConfig = {
    style: {},
    jsFile: "",
    data: {
      orderId: localStorage.getItem("TXN_ORDER"),
      amount: localStorage.getItem("TXN_AMOUNT"),
      token: localStorage.getItem("TXN_TOKEN"),
      tokenType: "TXN_TOKEN",
      mid: process.env.REACT_APP_PAYTM_MID,
      userDetail: {
        mobileNumber: "",
        name: "",
      },
    },
    merchant: {
      mid: process.env.REACT_APP_PAYTM_MID,
      name: "BassInterio",
      logo: "",
      redirect: true,
    },
    mapClientMessage: {},
    labels: {},
    payMode: {
      labels: {},
      filter: {
        exclude: [],
      },
      order: ["NB", "CARD", "LOGIN"],
    },
    flow: "DEFAULT",
  };

  const [siteTitle, setSiteTitle] = useState(HConfig["SITE_TTILE"]);
  const HOST = process.env.REACT_APP_PAYTM_HOST_URL;
  const MID = process.env.REACT_APP_PAYTM_MID;
  const [mConfig, setMConfig] = useState(appendHandler(PaytmConfig));
  const [showCheckout, setShowCheckout] = useState(true);
  const [openInPopup, setOpenInPopup] = useState(true);
  const [checkoutJsInstance, setCheckoutJsInstance] = useState(null);

  // var config = {
  //   root: "",
  //   flow: "DEFAULT",
  //   data: {
  //     orderId: localStorage.getItem("TXN_ORDER"),
  //     token: localStorage.getItem("TXN_TOKEN"),
  //     tokenType: "TXN_TOKEN",
  //     amount: localStorage.getItem("TXN_AMOUNT"),
  //   },
  //   handler: {
  //     notifyMerchant: function (eventName, data) {
  //       console.log("notifyMerchant handler function called");
  //       console.log("eventName => ", eventName);
  //       console.log("data => ", data);
  //     },
  //   },
  // };
  const renderUpdateConfig = () => {
    setMConfig(getUpdatedMerchantConfig());
  };

  const getUpdatedMerchantConfig = () => {
    //const config = parse(mConfigTextAreaRef.current.value);
    return appendHandler(PaytmConfig);
  };

  const parse = (value) => {
    try {
      return JSON.parse(value);
    } catch (err) {
      console.error("Invalid config JSON");
      return {};
    }
  };
  const toggleOpenInPopup = () => {
    setOpenInPopup(!openInPopup);
  };
  const toggleCheckout = () => {
    setShowCheckout(!showCheckout);
  };
  const loadCheckoutScript = () => {
    renderUpdateConfig();
    setOpenInPopup(true);
    //const url = HOST;
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = HOST;
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          setMConfig(getUpdatedMerchantConfig());
          setCheckoutJsInstance(checkoutJsInstance);
        });
      } else {
        console.error(USE_EXISTING_CHECKOUT_INSTANCE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error(USE_EXISTING_CHECKOUT_INSTANCE + "script load fail!");
    };
    document.body.appendChild(scriptElement);
  };

  const getCheckoutJsObj = () => {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    } else {
      console.error(
        USE_EXISTING_CHECKOUT_INSTANCE + "Checkout instance not found!"
      );
    }

    return null;
  };
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Payment Process</title>
        <meta name="description" content={siteTitle + " -Payment Process"} />
        <meta name="keywords" content="Checkout"></meta>
        <script
          type="application/javascript"
          src={HOST}
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <SiteBreadcrumb title="Checkout" />

      <section className="checkout-area ptb-60">
        <div className="container">
          {/* <div className="row">
                          <div className="col-lg-12 col-md-12">
                              <div className="user-actions">
                                  <i className="fas fa-sign-in-alt"></i>
                                  <span>Returning customer? <Link to="#">Click here to login</Link></span>
                              </div>
                          </div>
                      </div> */}

          <div>
            {/* Show a loading message or any other UI while processing the payment */}
            <p>Processing payment...</p>
          </div>
        </div>
      </section>
      <CheckoutProvider
        config={mConfig}
        checkoutJsInstance={checkoutJsInstance}
        openInPopup={openInPopup}
        env="STAGE"
      >
        {/* <InjectedCheckout /> */}
        {showCheckout && <Checkout />}
      </CheckoutProvider>
      <BestFacilities />
    </>
  );
};

export default PaymentProcess;
