import React from 'react';
import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
//console.log(root);
// root.render(

  
//     <React.StrictMode>
//       <BrowserRouter>

//         <App />

//       </BrowserRouter>

//     </React.StrictMode>


// );

root.render (
  <React.StrictMode>
    <BrowserRouter>

      <App />

    </BrowserRouter>

  </React.StrictMode>
);

//render(<AppNew />, root);
// if (root) {
//   hydrate(<AppNew />, root);
// } else {
//   render(<AppNew />, root);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
