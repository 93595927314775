import React, { useEffect, useState } from "react";
// import Link from 'next/link';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";

import Partner1Image from "../../resources/themeContent/images/partner1.png";
import Partner2Image from "../../resources/themeContent/images/partner2.png";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import {
  checkIfStringIsEmtpy,
  showInfoMsg,
} from "../../../helpers/ValidationHelper";
import {
  makeAnyStringLengthShort,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  setLanguageCodeInSession,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import LoginUserModal from "../modal/LoginUserModal";
import Wishlist from "../modal/Wishlist";

const MegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchForm, setSearchForm] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [SearchTerm, setSearchTerm] = useState("");
  const [CategoriesList, setCategoriesList] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LogoImageFromStorage, setLogoImageFromStorage] = useState("");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");
  const [display, setDisplay] = useState(false);
  const [displayLoginUserModal, setDisplayLoginUserModal] = useState(false);
  const [displaySubMenuImages, setdisplaySubMenuImages] = useState([]);
  const handleSearchForm = () => {
    setSearchForm(!searchForm);
  };
  const [firstFiveItems, setFirstFiveItem] = useState([]);
  const [nextFiveItems, setNextFiveItem] = useState([]);

  const submitSearchForm = () => {
    if (
      SearchTerm != null &&
      SearchTerm != undefined &&
      SearchTerm.length > 1
    ) {
      let url =
        "/" +
        getLanguageCodeFromSession() +
        "/all-products/0/all-categories?SearchTerm=" +
        SearchTerm;
      setSearchForm(!searchForm);

      navigate(url, { replace: true });
      window.location.reload();
    }
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenID, setDropdownOpenID] = useState(null);
  const [isBackArrowVisible, setBackArrowVisible] = useState(false);
  const [selectedMainCatId, setSelectedMainCatId] = useState(-1);
  const hideDropdown = () => {
    setDropdownOpenID(null);
  };

  const navigateOnCategoryClick = (CategoryID, categoryName, e) => {
    CategoryID = CategoryID ?? 0;
    categoryName = categoryName ?? "all-categories";

    setDropdownOpen(!isDropdownOpen);
    if (isDropdownOpenID == CategoryID) {
      setDropdownOpenID(null);
    } else {
      setDropdownOpenID(CategoryID);
    }

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products/${CategoryID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      categoryName
    )}`;

    console.log("Sub clicked!");
    if (window.innerWidth >= 767.98) {
    }
    window.location.href = newPageUrl;
    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    //  navigate(newPageUrl);

    e.preventDefault();
  };

  const navigateTopMenuOnCategoryClick = (CategoryID, categoryName, e) => {
    CategoryID = CategoryID ?? 0;
    categoryName = categoryName ?? "all-categories";
    setSelectedMainCatId(CategoryID);
    setDropdownOpen(!isDropdownOpen);
    if (isDropdownOpenID == CategoryID) {
      setDropdownOpenID(null);
    } else {
      setDropdownOpenID(CategoryID);
    }

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products/${CategoryID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      categoryName
    )}`;
    var tempCategory = [];
    var tempSubCatList = [];
    CategoriesList?.filter((t) => t.ParentCategoryID === CategoryID).map(
      (item, id) => {
        tempCategory.push(item);
      }
    );

    tempCategory?.map((item, id) => {
      var subItems = CategoriesList?.filter(
        (t) => t.ParentCategoryID === item.CategoryID
      );
      subItems?.map((childitem, idd) => {
        tempSubCatList.push(childitem);
      });
    });
    console.log(tempSubCatList);
    // CategoriesList?.filter((t) => t.ParentCategoryID === CategoryID).map(
    //   (subElement, idxSubElement) =>
    //     CategoriesList?.filter(
    //       (t) => t.ParentCategoryID === subElement.CategoryID
    //     ).map((subSubElement, idxSubSubElement) => {
    //       // Do something with subSubElement
    //       // Example: setdisplaySubMenuImages(subSubElement);

    //       setdisplaySubMenuImages(subSubElement);
    //     })
    // );

    console.log("fiveItem");
    setFirstFiveItem(tempSubCatList.slice(0, 5));
    console.log(firstFiveItems);

    setNextFiveItem(tempSubCatList.slice(5, 10));
    console.log(nextFiveItems);

    console.log("Top clicked!");
    if (window.innerWidth >= 767.98) {
      window.location.href = newPageUrl;
    }

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    //  navigate(newPageUrl);

    e.preventDefault();
  };

  useEffect(() => {
    // Select the first element with the class "navbar-area"
    let elementId = document.querySelector(".navbar-area");

    if (elementId) {
      // Check if elementId is not undefined
      document.addEventListener("scroll", () => {
        if (window.scrollY > 170) {
          elementId.classList.add("is-sticky");
        } else {
          elementId.classList.remove("is-sticky");
        }
      });
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("scroll", () => {
        if (elementId) {
          elementId.classList.remove("is-sticky");
        }
      });
    };
  }, []);

  useEffect(() => {
    //console.clear();
    const getDataInUseEffect = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 100,
          recordValueJson: "[]",
        },
      };

      //--Get categories list
      const categoriesResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CATEGORIES_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (categoriesResponse != null && categoriesResponse.data != null) {
        await setCategoriesList(JSON.parse(categoriesResponse.data.data));
        //  console.log("Header Menu Categories List:");
        // console.log(JSON.parse(categoriesResponse.data.data));
      }

      //--Get Website Logo
      if (!checkIfStringIsEmtpy(LogoImageFromStorage)) {
        let paramLogo = {
          requestParameters: {
            recordValueJson: "[]",
          },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_WEBSITE_LOGO"],
          null,
          paramLogo,
          headers,
          "POST",
          true
        );
        if (logoResponse != null && logoResponse.data != null) {
          // console.log(logoResponse.data);

          if (logoResponse.data.data != "") {
            let logoData = JSON.parse(logoResponse.data.data);
            WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
            dispatch(
              rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage)
            );
            setLogoImageFromStorage(WebsiteLogoInLocalStorage);
          }
        }
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["MegaMenu"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getDataInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  // collapsed submenu

  const [isSubMenuOpen, setIsSubMenuOpen] = useState([true]); // Initialize with the desired number of elements

  const toggleSubMenu = (index) => {
    setIsSubMenuOpen((prevIsSubMenuOpen) => {
      const updatedIsSubMenuOpen = [...prevIsSubMenuOpen]; // Create a copy of the state array
      updatedIsSubMenuOpen[index] = !updatedIsSubMenuOpen[index]; // Toggle the value at the specified index
      return updatedIsSubMenuOpen; // Return the updated array as the new state
    });
  };

  const checkScreenWidthAndApplyBehavior = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      // The screen width is 767px or less, apply your behavior here
      // For example, call toggleSubMenu to toggle values at specific indices
      toggleSubMenu(0);
    }
  };

  useEffect(() => {
    // Add an event listener to check the screen width when the window resizes
    window.addEventListener("resize", checkScreenWidthAndApplyBehavior);

    // Initial check when the component mounts
    checkScreenWidthAndApplyBehavior();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenWidthAndApplyBehavior);
    };
  }, []);

  const handleOpenCloseLoginUserModal = (event) => {
    event.preventDefault();
    setDisplayLoginUserModal(!displayLoginUserModal);
  };

  const handleWishlist = () => {
    setDisplay(!display);
  };

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const HandleLogout = (e) => {
    localStorage.setItem("user", JSON.stringify("{}"));
    dispatch(rootAction.userAction.setUser("{}"));

    navigate("/", { replace: true });
  };

  const totalCartItems = useSelector(
    (state) => state.cartReducer.totalCartItems
  );

  const handleCart = (event) => {
    event.preventDefault();

    if (
      totalCartItems != null &&
      totalCartItems != null &&
      totalCartItems > 0
    ) {
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    } else {
      showInfoMsg("No item exists in your cart");
    }
  };

  const handleLangCodeInSession = async (value) => {
    await setLanguageCodeInSession(value);
    await setLangCode(value);
    let homeUrl = "/" + value + "/";
    window.location.href = homeUrl;
    // navigate(homeUrl, { replace: true });
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["TopHeader"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <div className="navbar-area">
        <div className="container">
          <div id="navbar" className="comero-nav">
            <div className="navbartop">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-4">
                  <div className="d-flex mainlogo">
                    <div className="d-flex align-items-center">
                      <button
                        onClick={toggleNavbar}
                        className={classTwo}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="icon-bar top-bar"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                      </button>
                      <Link
                        to={`/${getLanguageCodeFromSession()}/`}
                        className="navbar-brand"
                      >
                        <img
                          src={adminPanelBaseURL + LogoImageFromStorage}
                          width={180}
                          height={70}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="menuoptions d-md-none d-block">
                      <div className="others-option">
                        <div className="topbarright d-flex justify-content-md-end justify-content-center">
                          {loginUser != null &&
                          loginUser != undefined &&
                          loginUser.UserID != undefined &&
                          loginUser.UserID > 0 ? (
                            <>
                              <div className="option-item  text-center me-0">
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenCloseLoginUserModal(e);
                                  }}
                                  id="lbl_mgmenu_logout"
                                  className="d-flex flex-column text-center log_link"
                                >
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Logout",
                                        "lbl_mgmenu_logout"
                                      )
                                    : ""}
                                  <>
                                    <i className="fas fa-user"></i>
                                    <span>{loginUser.FirstName}</span>
                                  </>
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="option-item  text-center me-0">
                                <Link
                                  className="d-flex flex-column text-center log_link"
                                  to={`/${getLanguageCodeFromSession()}/login`}
                                  id="lbl_mgmenu_login"
                                >
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Login",
                                        "lbl_mgmenu_login"
                                      )
                                    : ""}
                                  <>
                                    <i className="fas fa-user"></i>
                                    <span>Login</span>
                                  </>
                                </Link>
                              </div>
                            </>
                          )}
                          <ul className="top-header-right-nav mx-2">
                            <li>
                              <Link
                                to="#"
                                data-toggle="modal"
                                data-target="#shoppingWishlistModal"
                                onClick={() => handleWishlist()}
                                className="d-flex flex-column text-center"
                              >
                                <span
                                  id="lbl_thead_wishlist"
                                  className="d-flex flex-column "
                                >
                                  <i className="far fa-heart"></i>
                                  <span>
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Wishlist",
                                          "lbl_thead_wishlist"
                                        )
                                      : "Wishlist"}
                                  </span>{" "}
                                </span>
                              </Link>
                            </li>
                          </ul>
                          <div className="others-option d-flex">
                            <div className="option-item text-center ">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  handleCart(e);
                                }}
                                className="d-flex flex-column"
                              >
                                <i className="fas fa-shopping-bag"></i>
                                <div className="d-flex align-item-center">
                                  <span id="lbl_mgmenu_cart">
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Cart",
                                          "lbl_mgmenu_cart"
                                        )
                                      : "Cart"}
                                  </span>
                                  <span>({totalCartItems ?? 0}) </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="option-item menuright d-md-none d-block mt-md-0 mt-2">
                    <div className="search-box position-relative">
                      <form className="search-form">
                        <input
                          className="search-input"
                          name="search"
                          placeholder="Search"
                          type="text"
                          value={SearchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button
                          className="search-button"
                          type="button"
                          onClick={() => submitSearchForm()}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-8">
                  <div className="menuoptions d-md-block d-none">
                    <div className="others-option">
                      <div className="option-item menuright">
                        <div className="search-box position-relative">
                          <form className="search-form">
                            <input
                              className="search-input"
                              name="search"
                              placeholder="Search"
                              type="text"
                              value={SearchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button
                              className="search-button"
                              type="button"
                              onClick={() => submitSearchForm()}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="topbarright d-flex justify-content-md-end justify-content-center">
                        {loginUser != null &&
                        loginUser != undefined &&
                        loginUser.UserID != undefined &&
                        loginUser.UserID > 0 ? (
                          <>
                            <div className="option-item  text-center me-0">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenCloseLoginUserModal(e);
                                }}
                                id="lbl_mgmenu_logout"
                                className="d-flex flex-column text-center log_link"
                              >
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Logout",
                                      "lbl_mgmenu_logout"
                                    )
                                  : ""}
                                <>
                                  <i className="fas fa-user"></i>
                                  <span>{loginUser.FirstName}</span>
                                </>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="option-item  text-center me-0">
                              <Link
                                className="d-flex flex-column text-center log_link"
                                to={`/${getLanguageCodeFromSession()}/login`}
                                id="lbl_mgmenu_login"
                              >
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Login",
                                      "lbl_mgmenu_login"
                                    )
                                  : ""}
                                <>
                                  <i className="fas fa-user"></i>
                                  <span>Login</span>
                                </>
                              </Link>
                            </div>
                          </>
                        )}
                        <ul className="top-header-right-nav mx-4 px-2">
                          <li>
                            <Link
                              to="#"
                              data-toggle="modal"
                              data-target="#shoppingWishlistModal"
                              onClick={() => handleWishlist()}
                              className="d-flex flex-column text-center"
                            >
                              <i className="far fa-heart"></i>
                              <span id="lbl_thead_wishlist">
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Wishlist",
                                      "lbl_thead_wishlist"
                                    )
                                  : "Wishlist"}
                              </span>{" "}
                            </Link>
                          </li>
                        </ul>
                        <div className="others-option d-flex">
                          <div className="option-item text-center ">
                            <Link
                              to="#"
                              onClick={(e) => {
                                handleCart(e);
                              }}
                              className="d-flex flex-column"
                            >
                              <i className="fas fa-shopping-bag"></i>
                              <div className="d-flex align-item-center">
                                <span id="lbl_mgmenu_cart">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Cart",
                                        "lbl_mgmenu_cart"
                                      )
                                    : "Cart"}
                                </span>
                                <span>({totalCartItems ?? 0}) </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <nav className="navbar navbar-expand-md navbar-light">
              <div className={classOne} id="navbarSupportedContent">
                <div className="menulogo d-md-none d-block">
                  <Link
                    to={`/${getLanguageCodeFromSession()}/`}
                    className="navbar-brand"
                  >
                    <img
                      src={adminPanelBaseURL + LogoImageFromStorage}
                      width={180}
                      height={70}
                      alt="logo"
                    />
                  </Link>
                  <button
                    onClick={toggleNavbar}
                    className={classTwo}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar top-bar"></span>
                    <span className="icon-bar middle-bar"></span>
                    <span className="icon-bar bottom-bar"></span>
                  </button>
                </div>
                <ul className="navbar-nav">
                  {/* <li className="nav-item p-relative">
                                            <Link to="/" className="nav-link active" onClick={e => e.preventDefault()}>
                                                  Home 
                                            </Link>

                                           
                                        </li> */}
                  {/* <li className="nav-item p-relative">
                    <Link
                      to={`/${getLanguageCodeFromSession()}/`}
                      className="nav-link active"
                      id="lbl_mgmenu_home"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Home",
                            "lbl_mgmenu_home"
                          )
                        : "Home"}
                    </Link>
                  </li> */}
                  {/* <li className="nav-item p-relative">
                    <Link
                      to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}
                      className="nav-link active"
                      id="lbl_mgmenu_products"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "All Products",
                            "lbl_mgmenu_products"
                          )
                        : "All Products"}
                    </Link>
                  </li> */}
                  {/* <li className="nav-item p-relative">
                                        <Link to="#" className="nav-link active" onClick={e => e.preventDefault()}>
                                            Home <i className="fas fa-chevron-down"></i>
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link to="/" className="nav-link active">
                                                    Home One
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link">

                                                    Grocery
                                                    <span className="new">New</span>

                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}
                  {}

                  {CategoriesList?.filter(
                    (item) => item.ParentCategoryID === undefined
                  ).map((element, idxElement) => {
                    // Code to handle categories with ParentCategoryID as null

                    let ProductsUrl =
                      "/all-products?CategoryID=" + element.CategoryID;

                    return (
                      <li className="nav-item megamenu">
                        <Link
                          to="#"
                          className={`nav-link ${
                            isDropdownOpenID == element.CategoryID
                              ? "active"
                              : ""
                          }`}
                          onClick={(e) => {
                            navigateTopMenuOnCategoryClick(
                              element.CategoryID,
                              element.Name,
                              e
                            );
                          }}
                        >
                          <div className="catimg">
                            <img
                              className="d-md-none d-block"
                              src={adminPanelBaseURL + element.AttachmentURL}
                            />
                          </div>
                          <span id="lbl_mgmenu_category">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Categories",
                                  "lbl_mgmenu_category"
                                )
                              : element.Name}
                          </span>{" "}
                        </Link>
                        <ul
                          className={`dropdown-menu ${
                            isDropdownOpenID == element.CategoryID ? "show" : ""
                          }`}
                        >
                          <div className="menulogo menusublogo d-md-none d-flex align-items-center">
                            <div className="backarrow" onClick={hideDropdown}>
                              <i class="fas fa-arrow-left"></i>
                            </div>
                            <Link
                              to={`/${getLanguageCodeFromSession()}/`}
                              className="navbar-brand"
                            >
                              <img
                                src={adminPanelBaseURL + LogoImageFromStorage}
                                width={180}
                                height={70}
                                alt="logo"
                              />
                            </Link>
                          </div>
                          <div className="shop-categorys d-md-none d-block">
                            <div className="top-list">
                              {firstFiveItems.map((item, id) => {
                                return (
                                  <a
                                    onClick={(e) => {
                                      navigateOnCategoryClick(
                                        item.CategoryID,
                                        item.Name,
                                        e
                                      );
                                    }}
                                  >
                                    <img
                                      width={40}
                                      height={40}
                                      src={
                                        adminPanelBaseURL +
                                        item.AttachmentURL +
                                        "?v=40"
                                      }
                                    />
                                    <h5>{item.Name}</h5>
                                  </a>
                                );
                              })}
                              ;
                            </div>
                            <div className="top-list">
                              {nextFiveItems.map((item, id) => {
                                return (
                                  <a href="/">
                                    <img
                                      width={40}
                                      height={40}
                                      src={
                                        adminPanelBaseURL +
                                        item.AttachmentURL +
                                        "?v=40"
                                      }
                                    />
                                    <h5>{item.Name}</h5>
                                  </a>
                                );
                              })}
                              ;
                            </div>
                          </div>
                          <li className="nav-item">
                            <div className="container">
                              <div className="row">
                                {CategoriesList?.filter(
                                  (t) =>
                                    t.ParentCategoryID == element.CategoryID
                                ).map((subElement, idxSubElement) => {
                                  return (
                                    <div className="col">
                                      <h6
                                        className={`submenu-title ${
                                          isSubMenuOpen[idxSubElement]
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          toggleSubMenu(idxSubElement)
                                        }
                                      >
                                        {subElement.Name}
                                      </h6>
                                      <ul
                                        className={`megamenu-submenu ${
                                          isSubMenuOpen[idxSubElement]
                                            ? "show"
                                            : ""
                                        }`}
                                        style={{
                                          maxHeight: isSubMenuOpen[
                                            idxSubElement
                                          ]
                                            ? "500px"
                                            : "0",
                                        }}
                                      >
                                        {/* <ul className={`megamenu-submenu ${isSubSubMenuOpen == element.CategorySubID ? 'show' : ''}`} style={{ maxHeight: isSubMenuOpen ? '500px' : '0' }}> */}

                                        {CategoriesList?.filter(
                                          (t) =>
                                            t.ParentCategoryID ==
                                            subElement.CategoryID
                                        ).map(
                                          (subSubElement, idxSubSubElement) => {
                                            return (
                                              <li>
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    navigateOnCategoryClick(
                                                      subSubElement.CategoryID,
                                                      subSubElement.Name,
                                                      e
                                                    );
                                                  }}
                                                >
                                                  {subSubElement.Name}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  );
                                })}
                                <div className="col">
                                  <figure class="width-one">
                                    <a href="living-room-furniture?type=header_menu_living">
                                      <img
                                        src={
                                          adminPanelBaseURL +
                                          "content/commonImages/otherImages/" +
                                          element.Name.replace(/\s+/g, "") +
                                          ".jpg"
                                        }
                                        alt="image"
                                      />
                                    </a>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {display ? <Wishlist handleWishlist={handleWishlist} /> : ""}

      {loginUser != null &&
      loginUser != undefined &&
      loginUser.UserID != undefined &&
      loginUser.UserID > 0 &&
      displayLoginUserModal ? (
        <LoginUserModal
          handleOpenCloseLoginUserModal={handleOpenCloseLoginUserModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MegaMenu;
